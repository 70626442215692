import * as React from "react";
import { Helmet } from "react-helmet";
import { Link, graphql } from "gatsby";
import Layout from "../components/layout";
import Arrow from "../icons/arrow";

import { GatsbyImage, getImage } from "gatsby-plugin-image";
const TagRoute = (props) => {
  const posts = props.data.allMarkdownRemark.edges;

  const postLinks = posts.map((post) => (
    <li key={post.node.fields.slug}>
      <Link to={post.node.fields.slug}>
        <div className={""}>
          <GatsbyImage
            image={getImage(post.node.frontmatter.featuredimage)}
            className={"w-full h-72 md:h-[450px]"}
          />
        </div>
        <h2 className="block pt-4 my-6 text-xl">
          {post.node.frontmatter.title}
        </h2>
      </Link>
    </li>
  ));

  const { tag } = props.pageContext;
  const { title } = props.data.site.siteMetadata;
  const { totalCount } = props.data.allMarkdownRemark;
  const tagHeader = `${totalCount} post${
    totalCount === 1 ? "" : "s"
  } tagged with “${tag}”`;

  return (
    <Layout>
      <section className="section">
        <Helmet title={`${tag} | ${title}`} />
        <div className="w-full max-w-screen-xl px-6 mx-auto content">
          <div className={""}>
            <div
              className="max-w-5xl pt-8 pb-12 columns md:py-16"
            >
              <Link to={`/blog`} className="flex items-center">
                <div className={`rotate-180 mr-2`}>
                  <Arrow color="#404040" width="w-6" />
                </div>
                Blog
              </Link>
              <div className={`md:py-10`}>
                <h1 className="lg:pr-12">{tagHeader}</h1>
              </div>
              <ul className="grid gap-8 md:gap-x-8 md:gap-y-16 md:grid-cols-2 lg:grid-cols-3">
                {postLinks}
              </ul>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default TagRoute;

export const tagPageQuery = graphql`
  query TagPage($tag: String) {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(
      limit: 1000
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { tags: { in: [$tag] } } }
    ) {
      totalCount
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            title
            featuredimage {
              childImageSharp {
                gatsbyImageData(quality: 100, layout: CONSTRAINED)
              }
            }
          }
        }
      }
    }
  }
`;
